.container, .home .subtitleSection {
    max-width: 37rem;
    padding: 0;
}

.container {
    padding: 0 1rem;
    margin-top: 0.5rem;
}

.container .text {
    padding: 0;
}

.container .text div {
    text-align: left;
}

@media (min-width: 700px) {
    .container, .home .subtitleSection {
        max-width: 37rem;
    }

}


html, body, #root, .App {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 100;
  font-size: 0.87rem;
  line-height: 1.3;
  color: #858585;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
}

.container .text div {
  text-align: left;
}

.container, .subtitleSection {
  max-width: 37rem;
  padding: 0;
}

.container {
  padding: 0 1.5rem;
  padding: 0 2.1rem;
  margin-top: 0.5rem;
}

.container .text {
  padding: 0 2.1rem;
}

.container .text div {
  text-align: left;
}

@media (min-width: 700px) {
  .home .container, .home .subtitleSection {
    max-width: 37rem;
  }
  .home.cellView .container .text {
    padding: 0;
  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="text"], input[type="search"], input[type="button"], input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0;
}

.hidden {
  display: none;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}
.navigation {
    /* max-width: 35rem; */
    position: relative;
    top: 0;

    width: 6rem;
    margin-right: auto;
    z-index: 100;
}
.navigation ion-icon {
    font-size: 24px;
}
.navigation .top {
    display: flex;
    height: 4rem;
    padding: 1rem;
    font-size: larger;
}

.navigation .top ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;
}

.navigation .top a {
    color: #555;
}

.navigation .top .left {
    float: left;
}

.navigation .top .middle {
    margin: auto;
}

.navigation .top .right {
    float: right;
}

.navigation .top ul li {
    list-style: none;
}

.navigation .top .left ul li {
    margin-right: 1rem;
}

.navigation .top .right ul li {
    margin-left: 1rem;
}


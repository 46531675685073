@media (min-width: 700px) /* Desktop */ {
    .cell.thumbdesc .imageContainer {
        width: 8rem;
        height: 8rem;
        margin: 0 1rem 0 0;
        float: left;
        /*
        overflow: hidden;
        position: relative;

         */
    }

    .cell.thumbnail .imageContainer {
        height: 14rem;
        position: relative;
        overflow-x: hidden;
    }

    .cell.thumbnail {
      /*  width: 11rem; */
    }

    .cell .imageContainer .imagePlaceHolder {
        color: #555;
        font-size: 6rem;
        font-weight: bolder;
        margin: 0.5em auto;
        text-decoration: none;
    }

}
@media screen and (max-width: 700px) and (min-width: 400px) and (min-height: 700px) /* Iphone X */ {
    .cell.thumbnail, .cell.stamp {
        min-height: 10rem;
    }
    .cell.stamp {
        width: 7rem;
    }
    .cell.thumbnail .imageContainer {
        height: 10rem;
    }
    .cell.stamp .imageContainer {
        height: 8rem;
        width: 7rem;
    }
}

@media (max-width: 320px) /* Iphone5 */ {
    .cell.thumbnail, .cell.stamp {
        min-height: 8rem;
    }
    .cell.stamp {
        width: 5rem;
    }
    .cell.thumbnail .imageContainer {
        height: 7rem;
    }
    .cell.stamp .imageContainer {
        height: 6rem;
        width: 5rem;
    }

}
@media (max-width: 280px) /* Galaxy Fold */ {
    .cell.thumbnail, .cell.stamp {
        min-height: 8rem;
    }
    .cell.stamp {
        width: 5rem;
    }
    .cell.thumbnail .imageContainer {
        height: 6rem;
    }
    .cell.stamp .imageContainer {
        height: 6rem;
        width: 5rem;
    }

}


.searchResult {
    margin-top: 2rem;
}
.searchResult h6 {
    width: 22rem;
    margin: 0 auto;
    font-size: small;
    text-align: left;
    text-transform: uppercase;
    color: #555;
    /* border-top: 1px solid #EEE; */
}
.searchList {
    display: flex;
    flex-wrap: wrap;
    clear: both;
    align-content: flex-start;
    justify-content: space-between;
    width: 22rem;
    margin: 1rem auto;
    z-index: 1000;
}
.searchResult.featured .searchList, .searchResult.few .searchList {
    justify-content: left;
}
.searchResult.featured .searchList {
    overflow-x: auto;
}
.searchResult.featured .searchList .wrapper {
    display: inline-flex;
}
.search .subtitleSection .prev, .search .subtitleSection .next {
    display: none;
}
@media (min-width: 45rem) /* Desktop */ {
    .searchList, .searchResult h6 {
        width: 42rem;
    }
}
@media (max-width: 45rem) /* Mobile landscape */ {
    .searchList, .searchResult h6 {
        width: 35rem;
    }
}
@media (max-width: 37rem) /* Mobile landscape */ {
    .searchList, .searchResult h6 {
        width: 28rem;
    }
}
@media (max-width: 30rem) /* Mobile landscape */ {
    .searchList, .searchResult h6 {
        width: 21rem;
    }
}
@media (max-width: 320px) /* Iphone5 */ {
    .searchList, .searchResult h6 {
        width: 18rem;
    }
}
@media (max-width: 280px) /* Galaxy Fold */ {
    .searchList, .searchResult h6 {
        width: 15rem;
    }
}
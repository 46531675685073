.quickNavTitle {
    position: absolute;
    top: 0;
    width: 100%;
    height: 3.6rem;
    padding: 1rem;
    /* background-color: #6FF2; */
    z-index: 10;
    /* border-bottom: 1px solid silver; */
}

.quickNavTitle a {
    color: #858585;
}

.quickNavTitle .titleSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2rem;
}

.quickNavTitle h2 {
    height: 1.8rem;
    margin: 0;
    font-size: large;
    color: #858585;
    text-transform: capitalize;
}

.quickNavTitle h6 {
    text-transform: uppercase;
}

.quickNavTitle .titleSection ion-icon {
    font-size: 24px;
}

.quickNavTitle .titleSection .left, .quickNavTitle .titleSection .right {
    width: 6rem;
}

.quickNavTitle .titleSection ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;
}

.quickNavTitle .titleSection .right ul li {
    margin-left: auto;
}

.quickNavTitle .subtitleSection {
    display: flex;
    margin: 1.2rem auto;
    justify-content: space-between;
    align-items: baseline;
}

.subtitleSection .prev, .subtitleSection .next, .subtitleSection .prev a, .subtitleSection .next a {
    /* font-size: 5em;*/
    color: #858585;
    width: 3rem;
    display: block;
}
.subtitleSection .prev a:hover, .subtitleSection .next a:hover {
    background-color: #F7F7F7;
}
.subtitleSection .prev {
}
.subtitleSection .next {
}

.dropdown-item a {
    width: 100%;
    display: inline-block;
    padding: 0.5rem;
}

@media (min-width: 700px) {
    .subtitleSection .prev, .subtitleSection .next {
        position: absolute;
        display:block;
        height: 47rem;
    }
    .subtitleSection .prev {
        margin-left: -4rem;
    }
    .subtitleSection .next {
        margin-left: 1rem;
    }
    .subtitleSection .prev a, .subtitleSection .next a {
        height: 100%;
        padding-top: 14rem;
        font-size: 3rem;
    }
}
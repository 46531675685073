.cell h2 {
    font-size: larger;
    font-weight: 700;
    text-align: left;
    clear: left;
    margin: 2rem 0 0 0;
}
.cell.single h2 {
    font-size: large;
    margin: auto;
    width: 50%;
    top: -2.6rem;
    position: relative;
    background-color: white;
    text-align: center;
}

.cell h4 {
    font-size: medium;
    text-decoration: none;
    font-weight: 600;
    text-align: left;
}

.cell h5, .container h5 {
    font-size: small;
    font-weight: 300;
    text-transform: uppercase;
    text-align: left;
    margin: 0.5rem 0 0.5rem 0;
    color: #858585;
}
.cell.thumbnail h5, .cell.stamp h5 {
    height: 1rem;
    font-size: small;
    text-align: center;
    text-transform: lowercase;
    margin: 0.1rem 0 0.5rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.cell div.expandable {
    font-size: 0.9rem;
    font-weight: 50;
    margin-bottom: 1rem;
}

.cell {
    max-width: 38rem;
    min-height: 9rem;
    padding: 0;
    margin: 0 auto;
}

.cell.stamp {
    width: 5.5rem;
    width: 7rem;
    min-height: 9rem;
    float: left;
    margin: 0;
}

.cell.thumbnail {
    width: 7.6rem;
    width: 33%;
    float: left;
}

.cell .imageIcon {
    height: 1.5rem;
    margin: 0.4rem 0.4rem 0.6rem 0rem;
}

.cell .imageContainer {
    width: 100%;
    margin: auto;
}

.cell .imageContainer .LazyLoad {
    height: calc(100% - 5px);
    width: calc(100% - 5px);
    background-color: #eee;
}

.cell .imageContainer .LazyLoad.is-visible {
    background-color: inherit;
    width: 100%;
}

.cell.thumbdesc .imageContainer {
    width: 8rem;
    height: 8rem;
    margin: 0 1rem 0 0;
    float: left;
    /*
    overflow: hidden;
    position: relative;

     */
}

.cell.thumbnail .imageContainer {
    height: 7.7rem;
    position: relative;
    overflow-x: hidden;
}

.cell.stamp .imageContainer {
    height: 9rem;
    position: relative;
    overflow: hidden;
}

.cell .imageContainer .imagePlaceHolder {
    color: #555;
    font-size: 4rem;
    font-weight: bolder;
    margin: 0.5em auto;
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}

.cell.thumbnail a {
    display: inline-block;
}
.cell a {
    color: inherit;
  /*  display: block;*/
  /*  overflow: hidden; */
   /* text-align: center;*/
    width: 100%;

}

.cell.thumbdesc .imageContainer a {
    width: 100%;
    height: 8rem;
    display: block;
    position: relative;
    overflow: hidden;
}

.cell .mainImage {
    max-width: 100%;
    max-height: 28rem;
    width: auto;
    height: auto;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: inherit;
}

.cell.thumbdesc .mainImage {
    max-height: 100%;
    max-width: none;
    width: auto;
    height: auto;
    margin: auto;
    top: 0;
    right: -6rem;
    bottom: 0;
    left: -6rem;
    position: absolute;
    float: left;
}

.cell.thumbnail .mainImage, .cell.stamp .mainImage {
    max-height: 100%;
    max-width: none;
    width: auto;
    height: auto;
    margin: auto;
    top: 0;
    right: -100px;
    bottom: 0;
    left: -100px;
    position:absolute;
}

.cell div.expandable {
    text-align: justify;
}

.cell.thumbdesc .text {
    margin-left: 9rem;
}

.cell.single .text > div.expandable {
    min-height: 5rem;
}

.container .divide {
    /* border-top: 1px solid #BBB; */
    margin-bottom: 2rem;
}

.cell .text .split {
    display: flex;
    justify-content: space-between;
}

.cell .text .split .right h5 {
    text-align: right;
}

.cell .text .button {
    display: flex;
    margin: 0.5rem 0;
    padding: 0.3rem 0.5rem;
    border: 1px solid #BBB;
    border-radius: 0.3rem;
}

.cell .text .tag {
    display: inline-block;
    font-size: smaller;
    margin: 0.5rem 0.5rem 0 0;
    padding: 0.1rem 0.5rem;
    border-radius: 0.2rem;
    color: #FFF;
    background-color: #898F9C;
}



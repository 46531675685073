.grid {
    min-height: 420px;
}
.grid .gridList {
    margin: 2rem auto;
}
.grid .gridList .text {
    padding: 0;
}
.grid .gridList .clear {
    clear: both;
}


.search {
    width: 100%;
}
.search button {
    border: none;
    background: none;
    text-align: left;
    font-weight: normal;
    color: inherit;
}
.search button:focus {
    outline: none;
}
.search input {
    color: #858585;
    font-size: medium;
    width: calc(100% - 3rem + 10px);
    border: 1px solid silver;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    padding: 0.5rem 3rem 0.5rem 1rem;
    height: 2.5rem;
    display: inline-block;
    position: relative;
    inset: 0;
    outline: none;
}
.search .clear {
    display: inline-block;
    position: relative;
    top: 0.45rem;
    width: 2rem;
    text-align: left;
    font-size: 1.5rem;
    margin-left: -2rem;

}
.search a {
    color: inherit;
    width: 100%;
    height: 100%;
}
.search div#goButton {
    font-size: medium;
    border: 1px solid silver;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    padding: 0.55rem 0.5rem 0 0.5rem;
    margin-left: -2px;
    margin-top: -1px;
    height: 2.5rem;
    display: inline-block;
    position: relative;
    top: 0;
    background-color: white;
}
.search .ranges {
    width: calc(100% - 0rem);
    margin: 1rem auto;
    display: flex;
    justify-content: space-between;
}
.search .ranges a {
    padding: 0 1rem;
}
.search .ranges a.active {
    border-bottom: 1px solid #555;
    font-weight: bold;
}

.featured .search #refine {
    display: none;
}
.search #refine {
    padding: 0 1rem;
    font-size: small;
    text-align: left;
}
.search #refine.false .drawer {
    display: none;
}
.search #refine.true .drawer {
    display: block;
}
.search #refine button.button {
    padding: 0;
}
.search #refine .refinement {
    display: inline-block;
    margin: 0 0 0 2rem;
    font-weight: bold;
}
.search #refine .refinement:after {
    content: "\0000d7";
    position: relative;
    left: 0.2rem;
    top: -0.2rem;
}
.search #refine.true .refinements {
    float: left;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    margin: 0 3rem 2rem 0;
}

@media (max-width: 320px) /* Galaxy Fold, Iphone5 */ {
    .search .ranges {
        font-size: small;
        width: 100%;
    }
    .search .ranges a {
        padding: 0 0.5rem;
    }
}
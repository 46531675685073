.home .container, .home .subtitleSection {
    max-width: 37rem;
    padding: 0;
}

.home .container {
    padding: 0 1rem;
    margin-top: 0.5rem;
}
.home.cellView .container {
    padding: 0;
}

.home .container .text {
    padding: 0;
}

.home.cellView .container .text {
    padding: 0 1rem;
}

.home .container .text div {
    text-align: left;
}

@media (min-width: 700px) {
    .home .container, .home .subtitleSection {
        max-width: 37rem;
    }
    .home.cellView .container .text {
        padding: 0;
    }

}

